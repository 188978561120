'use client';

import { ComponentProps, FC, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

export const CurtainInView: FC<
  ComponentProps<'div'> & {
    orientation: string;
    rootMargin?: string;
    delay?: string;
    animationDuration?: string;
  }
> = ({
  children,
  className,
  orientation,
  delay = '1s',
  animationDuration = '0.5s',
  rootMargin = '-15% 0px -15% 0px',
}) => {
  const ref = useRef(null);
  const [inView, setInView] = useState(false);
  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        },
        {
          rootMargin,
        },
      );
      observer.observe(ref.current);
      return () => {
        if (ref.current) observer.unobserve(ref.current);
      };
    }
  }, [ref]);
  const style = {
    animationDuration: animationDuration,
    animationDelay: delay,
    transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  };
  let orientationClass;
  if (orientation === 'left') {
    orientationClass = styles.curtainLeft;
  } else if (orientation === 'right') {
    orientationClass = styles.curtainRight;
  } else if (orientation === 'top') {
    orientationClass = styles.curtainTop;
  } else if (orientation === 'bottom') {
    orientationClass = styles.curtainBottom;
  }

  return (
    <div
      ref={ref}
      style={style}
      className={`${styles.curtain} ${
        inView ? orientationClass : 'invisible'
      } ${className}`}
    >
      {children}
    </div>
  );
};
