'use client';

import { ComponentProps, FC, useEffect, useRef, useState } from 'react';

export const FadeUpInView: FC<
  {
    childClassName?: string;
    delay?: string;
    rootMargin?: string;
    className?: string;
  } & ComponentProps<'div'>
> = ({
  children,
  className,
  childClassName = '',
  delay = '100ms',
  rootMargin = '-15% 0px -15% 0px',
}) => {
  // Use Intersection Observer to fade in when in view
  const ref = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setInView(true);
          }
        });
      },
      {
        rootMargin,
      },
    );
    observer.observe(ref.current);
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);

  // Fade in and translate upwards
  return (
    <div ref={ref} className={className}>
      <div
        style={{ transitionDelay: delay }}
        className={`${childClassName} transition ease-in-out duration-700 transform ${
          inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
        }`}
      >
        {children}
      </div>
    </div>
  );
};
