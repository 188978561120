'use client';

import { ReactNode, useState } from 'react';
import styles from './styles.module.scss';
import { FaCheck } from 'react-icons/fa6';
import { tv } from 'tailwind-variants';

const wrapperVariant = tv({
  base: 'w-full scroll-mt-20',
});

const plusVariant = tv({
  base: 'transition-all text-gray-800 md:text-[1.2rem] select-none',
  variants: {
    open: {
      true: 'rotate-[135deg]',
    },
  },
});

const contentVariant = tv({
  base: 'transition-all md:w-[75%] px-4 md:px-0 ease-in-out font-light duration-300 overflow-hidden [&_a]:text-blue-500 [&_a]:underline',
  variants: {
    open: {
      true: 'h-auto my-8 opacity-100 ',
      false: 'h-0 opacity-0',
    },
    border: {
      true: 'ml-0 md:ml-40 xl:ml-36',
      false: 'ml-auto',
    },
  },
});

const titleWrapperVariant = tv({
  base: 'flex justify-between items-center px-4 xl:px-0 cursor-pointer py-4 gap-2',
  variants: {
    border: {
      true: 'border-b-[1px] border-gray-300',
      false: 'border-none',
    },
  },
});

const headingVariants = tv({
  base: 'md:max-w-36 font-semibold md:w-full md:text-xl',
  variants: {
    'max-width': {
      unset: 'md:max-w-none md:w-auto',
    },
  },
});

export const Accordion = ({
  id,
  title,
  heading,
  children,
  showTick,
  className,
  properties,
  hidePlus = false,
  hideBorder = false,
}: {
  id?: string;
  children: any;
  showTick?: boolean;
  className?: string;
  hideBorder?: boolean;
  hidePlus?: boolean;
  title: string | ReactNode;
  heading?: string;
  properties?: Record<string, any>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const headingWithStyledAsterisk = (content: string) => {
    const splitHeading = content.split(' ');
    return (
      <>
        {splitHeading.map((word, index) => {
          if (word === '*') {
            return (
              <span key={index} className="text-primary-500">
                {word}
              </span>
            );
          } else {
            return <span key={index}>{word} </span>;
          }
        })}
      </>
    );
  };
  return (
    <div id={id} className={wrapperVariant({ className })}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={titleWrapperVariant({ border: !hideBorder })}
      >
        <div className="flex gap-2 flex-1 ">
          {showTick && (
            <div className="flex-shrink-0 size-6 flex items-center justify-center">
              <FaCheck className=" fill-black" />
            </div>
          )}
          <div className="flex md:flex-row flex-col w-full">
            {heading && (
              <div className={headingVariants({ ...properties?.heading })}>
                {headingWithStyledAsterisk(heading)}
              </div>
            )}
            <div className="text-base md:text-xl font-light text-gray-800">{title}</div>
          </div>
        </div>

        {!hidePlus && <div className={plusVariant({ open: isOpen })}>+</div>}
      </div>
      <div
        className={contentVariant({
          open: isOpen,
          border: hideBorder,
          className: styles.faqAccordion,
        })}
      >
        {children}
      </div>
    </div>
  );
};
